import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import store from './store';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/bootstrap.custom.css';
// import './assets/styles/index.css';
import './assets/scss/main.scss'; 
import App from './App';
import reportWebVitals from './reportWebVitals';
import ShopRoute from './components/Route/ShopRoute';
import EmailVerifyScreen from './screens/EmailVerifyScreen';
import NotificationScreen from './screens/NotificationScreen';

import ShopHomeScreen from './screens/ShopHomeScreen';
import ProductEditScreen from './screens/shop/ProductEditScreen';

import ShopLoginScreen from './screens/shop/ShopLoginScreen';
import ShopOrderListScreen from './screens/shop/ShopOrderListScreen';
import ShopProductListScreen from './screens/shop/ShopProductListScreen';
import ShopEditScreen from './screens/shop/ShopEditScreen';
import ShopOwnerProfileScreen from './screens/shop/ShopOwnerProfileScreen';
import CheckoutCounterScreen from './screens/shop/CheckoutCounterScreen';
import BalanceScreen from './screens/shop/BalanceScreen';
import PricingScreen from './screens/shop/PricingScreen';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<App />}>
      <Route path='/login' element={<ShopLoginScreen />} />
      <Route path='/verify-email' element={<EmailVerifyScreen />} />
      <Route path='/pricing' element={<PricingScreen />} />

      <Route  path='' element={<ShopRoute />}>
        <Route  path='/' element={<ShopOrderListScreen />} />
        <Route  path='/productlist' element={<ShopProductListScreen />} />
        <Route  path='/product/:id/edit' element={<ProductEditScreen />} />
        <Route  path='/edit-shop' element={<ShopEditScreen />} />
        <Route  path='/profile' element={<ShopOwnerProfileScreen />} />
        <Route  path='/balance' element={<BalanceScreen />} />
        <Route  path='/checkout-counter' element={<CheckoutCounterScreen />} />
        <Route path='/home/:slug' element={<ShopHomeScreen />} />
        <Route path='/home/:slug/about' element={<ShopHomeScreen />} />
        <Route path='/home/:slug/contact' element={<ShopHomeScreen />} />
        <Route path='/notifications' element={<NotificationScreen />} />
      </Route>

      <Route path='*' element={<div>404 Not Found</div>} />
    </Route>
  )
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);

reportWebVitals();