import { apiSlice } from './apiSlice';
import { NOTIFICATIONS_URL } from '../constants/constants';

export const notificationsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getNotifications: builder.query({
            query: ({ readStatus = 'all', page = 1, limit = 20 }) => ({
              url: `${NOTIFICATIONS_URL}/?readStatus=${readStatus}&page=${page}&limit=${limit}`,
            }),
            providesTags: ['Notifications'],
        }),
        getUnreadCount: builder.query({
            query: () => `${NOTIFICATIONS_URL}/unread-count`,
            providesTags: ['Notifications'],
        }),
        getUnreadNotifications: builder.query({
            query: () => ({
                url: `${NOTIFICATIONS_URL}/unread`,
                method: 'GET',
            }),
            providesTags: ['Notifications'],
            keepUnusedDataFor: 5,
        }),
        markAllAsRead: builder.mutation({
            query: () => ({
                url: `${NOTIFICATIONS_URL}/read`,
                method: 'PUT',
            }),
            invalidatesTags: ['Notifications'],
        }),
        markNotificationAsRead: builder.mutation({
            query: (id) => ({
                url: `${NOTIFICATIONS_URL}/${id}/read`,
                method: 'PUT',
            }),
            invalidatesTags: ['Notifications'],
        }),
    }),
});

export const { 
    useGetNotificationsQuery,
    useGetUnreadCountQuery,
    useGetUnreadNotificationsQuery,
    useMarkAllAsReadMutation,
    useMarkNotificationAsReadMutation,
} = notificationsApiSlice;