import React from 'react';
import { Container, Row, Col, Accordion, Image, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const PricingScreen = () => {
    return (
        <Container className="py-5 text-center ">
            <h1 className="mb-5">料金体系のご案内</h1>

            <section className="mb-5 p-4 bg-light rounded text-center">
                <h2 className="mb-4 border-bottom pb-2">都度レンタルの基本料金</h2>
                <Row className="mb-5">
                    <Col md={4} className="mb-3 mb-md-0">
                        <div className="p-3 bg-white border rounded text-center shadow-sm">
                            <h3>４日レンタル</h3>
                            <p>収益： <strong>15%</strong></p>
                        </div>
                    </Col>
                    <Col md={4} className="mb-3 mb-md-0">
                        <div className="p-3 bg-white border rounded text-center shadow-sm">
                        <h3>２週間レンタル</h3>
                        <p>収益： <strong>25%</strong></p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="p-3 bg-white border rounded text-center shadow-sm">
                        <h3>１ヶ月レンタル</h3>
                        <p>収益： <strong>40%</strong></p>
                        </div>
                    </Col>
                </Row>
                <div className="mb-5">
                    <h3 className="mb-4 border-bottom pb-2">延長料金</h3>
                    <p>3日延長： <strong>4%</strong></p>
                    <p>一週間延長： <strong>10%</strong></p>
                </div>
                <div className="mb-5">
                    <h3 className="mb-4 border-bottom pb-2">紛失時</h3>
                    {/* <p>- 申告時または無断延長が3回続いた場合</p> */}
                    <p>
                        【レンタル＋紛失価格】＝ 商品価格 となるように保証します。<br />
                        ユーザーとのやり取りは全てふるぷら（弊社）が行うため、ストレスなく事業に専念していただけます。
                    </p>
                </div>
                {/* <div>
                    <h3 className="mb-4 border-bottom pb-2">ユーザーが音信不通</h3>
                    <p>
                        古着屋報酬は【レンタル＋紛失価格】＝商品価格となるように保証します。<br />
                        ユーザーとのやり取りは全てふるぷら（弊社）が行うため、ストレスなく事業に専念していただけます。
                    </p>
                </div> */}
            </section>

            <section className="mb-5 p-4 bg-light rounded">
                <h2 className="mb-4 border-bottom pb-2">サブスクレンタル</h2>
                <p>
                    1ヶ月で商品価格の<strong>40%</strong>を基準とし、借りられた日数の割合で収益が確定します。<br />
                    例）10日間のレンタルで、40 ÷ 30 × 10 % となります。
                </p>
            </section>

            <section className="mb-5 p-4 bg-light rounded">
                <h2 className="mb-4 border-bottom pb-2">シーズン補償</h2>
                <p>
                    2ヶ月以上レンタルされ、シーズンを逃した古着がある場合、<br />
                    ふるぷらが差額を買い取る補償制度です。
                </p>
                <Row className='d-flex justify-content-center'>
                    <Col md={4} className="mb-3">
                        <Accordion className="mt-3">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>補償の具体例を見る</Accordion.Header>
                                <Accordion.Body>
                                    <p>例）7000円の古着が2ヶ月レンタルされ、シーズンを逃してしまった場合：</p>
                                    <p>レンタル収益: <strong>5600円 (40%×2ヶ月)</strong></p>
                                    <p>
                                        シーズン補償金額: <strong>1400円 (100% - 40%×2)</strong>
                                    </p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </section>

            <section className="mb-5 p-4 bg-light rounded">
                <h2 className="mb-4 border-bottom pb-2">購入手数料</h2>
                <Row className='d-flex justify-content-center'>
                    <Col md={4} className="mb-3">
                        <div className="p-3 bg-white border rounded shadow-sm">
                            <h3>決済手数料</h3>
                            <p>
                                <strong>3.6%</strong>（決済ごと、小数点以下切捨て）+ <strong>40円</strong>
                            </p>
                        </div>
                    </Col>
                    <Col md={4} className="mb-3">
                        <div className="p-3 bg-white border rounded shadow-sm">
                            <h3>システム手数料</h3>
                            <p>
                                <strong>3%</strong>（決済ごと、小数点以下切捨て）
                            </p>
                        </div>
                    </Col>
                </Row>
            </section>

            <section className="mb-5 p-4 bg-light rounded">
                <h2 className="mb-4 border-bottom pb-2">収益のお引き出し</h2>
                <Row className='d-flex justify-content-center'>
                    <Col md={4} className="mb-3">
                        <div className="p-3 bg-white border rounded shadow-sm">
                            <h3>出金手数料</h3>
                            <p>振込手数料：一律 <strong>250円</strong></p>
                            <p>
                                事務手数料：<br />
                                ・2万円未満： <strong>500円</strong><br />
                                ・2万円以上： <strong>0円</strong>
                            </p>
                        </div>
                    </Col>
                </Row>
                <p>
                    <Link to="/balance">お金管理ページ</Link>から、収益の確認と引き出し申請を行うことができます。<br />
                    1~15日に申請した場合: 申請月の末日に振り込まれます<br />
                    16~末日に申請した場合: 翌月15日に振り込まれます
                </p>
            </section>

            {/* <section className="mb-5 p-4 bg-light rounded">
                <h2 className="mb-4 border-bottom pb-2">消費税</h2>
                <Row>
                    <Col md={6} className="mb-3">
                        <div className="p-3 bg-white border rounded shadow-sm">
                            <h3>購入</h3>
                            <p>加盟店さまが消費税を回収し、納税を行なってください。</p>
                        </div>
                    </Col>
                    <Col md={6} className="mb-3">
                        <div className="p-3 bg-white border rounded shadow-sm">
                        <h3>レンタル</h3>
                        <p>ふるぷらがユーザーから回収し、納税を行います。</p>
                        </div>
                    </Col>
                </Row>
            </section> */}
        </Container>
    );
};

export default PricingScreen;