// useShopOrders.js
import { useMemo } from 'react';
import { useGetMyShopOrdersQuery } from '../slices/ordersApiSlice';

export const useShopOrders = () => {
    const { data: ordersData, isLoading, error, refetch } = useGetMyShopOrdersQuery();

    const groupedUndelivered = useMemo(() => {
        const grouped = {};
        if (ordersData) {
        const { purchaseItems, rentalItems } = ordersData;

        purchaseItems.forEach(item => {
            if (!item.isDelivered && !item.isCanceled) {
            const orderId = item.orderId;
            if (!grouped[orderId]) grouped[orderId] = [];
            grouped[orderId].push({ ...item, serviceOption: 'purchase', delivered: false });
            }
        });

        rentalItems.forEach(item => {
            if (!item.isDelivered && !item.isCanceled) {
            const orderId = item.orderId;
            if (!grouped[orderId]) grouped[orderId] = [];
            grouped[orderId].push({ ...item, serviceOption: 'rental', delivered: false });
            }
        });
        }
        return grouped;
    }, [ordersData]);

    /**
     * レンタル中のアイテム (status: rented, extended, overdue)
     */
    const rentedItems = useMemo(() => {
        const items = [];
        if (ordersData) {
        const { rentalItems } = ordersData;
        rentalItems.forEach(item => {
            const isRentedStatus = ['rented', 'extended', 'overdue'].includes(item.status);
            // レンタル中かつ isDelivered=true、isCanceled=false を条件とする
            if (isRentedStatus && item.isDelivered && !item.isCanceled) {
            items.push({ ...item, serviceOption: 'rental', delivered: true });
            }
        });
        }
        return items;
    }, [ordersData]);

    /**
     * 配送済み
     */
    const delivered = useMemo(() => {
        const items = {};
        if (ordersData) {
        const { purchaseItems, rentalItems } = ordersData;
        
        purchaseItems.forEach(item => {
            if (item.isDelivered && !item.isCanceled) {
            if (!items[item.orderId]) items[item.orderId] = [];
            items[item.orderId].push({ ...item, serviceOption: 'purchase', delivered: true });
            }
        });

        rentalItems.forEach(item => {
            if (item.isDelivered && !item.isCanceled) {
            if (!items[item.orderId]) items[item.orderId] = [];
            items[item.orderId].push({ ...item, serviceOption: 'rental', delivered: true });
            }
        });
        }
        return items;
    }, [ordersData]);

    /**
     * キャンセル
     */
    const canceled = useMemo(() => {
        const items = {};
        if (ordersData) {
        const { purchaseItems, rentalItems } = ordersData;

        purchaseItems.forEach(item => {
            if (item.isCanceled) {
            if (!items[item.orderId]) items[item.orderId] = [];
            items[item.orderId].push({ ...item, serviceOption: 'purchase', delivered: false });
            }
        });

        rentalItems.forEach(item => {
            if (item.isCanceled) {
            if (!items[item.orderId]) items[item.orderId] = [];
            items[item.orderId].push({ ...item, serviceOption: 'rental', delivered: false });
            }
        });
        }
        return items;
    }, [ordersData]);

    return {
        ordersData,
        isLoading,
        error,
        refetch,
        groupedUndelivered,
        rentedItems,
        delivered,
        canceled,
    };
};