import { Helmet } from 'react-helmet-async';

const Meta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={ description } />
      <meta name='keywords' content={ keywords } />
    </Helmet>
  );
};

Meta.defaultProps = {
  title: "ふるぷら｜ショップ管理画面 - あなたの'古着'を、もっと多くの人に届けます",
  description: '下北、高円寺、吉祥寺。日本が誇るサブカル「古着」を存分に詰め込んだプラットフォームのショップ管理画面',
  keywords: '古着, 下北, 高円寺, レンタル, プラットフォーム',
};

export default Meta;