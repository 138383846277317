// src/components/QRReader.js
import { useEffect, useRef, useState } from 'react';
import { Html5Qrcode } from 'html5-qrcode';
import PropTypes from 'prop-types';
import { FaSync } from 'react-icons/fa';

const QRReader = ({ onScanSuccess, onScanError }) => {
  const qrCodeRef = useRef(null);
  const html5QrCodeRef = useRef(null);
  const isMountedRef = useRef(true);
  const isScanningRef = useRef(false);
  const [isScanning, setIsScanning] = useState(false);
  const uniqueIdRef = useRef(`reader-${Math.random().toString(36).substr(2, 9)}`);

  // カメラ一覧と現在のカメラインデックスのステート
  const [cameras, setCameras] = useState([]);
  const [currentCameraIndex, setCurrentCameraIndex] = useState(0);
  // 内カメラなら左右反転（mirror:true）、外カメラなら反転なし（mirror:false）
  const [isMirror, setIsMirror] = useState(true);

  // 共通設定（disableFlip は使用せず、transform で制御）
  const baseConfig = { fps: 5, qrbox: 250 };

  const qrCodeSuccessCallback = (decodedText, decodedResult) => {
    onScanSuccess(decodedText, decodedResult);
  };

  const qrCodeErrorCallback = (errorMessage) => {
    console.warn(`スキャンエラー: ${errorMessage}`);
    if (isMountedRef.current) {
      onScanError(errorMessage);
    }
  };

  // video 要素に mirror スタイルを適用する関数
  const updateVideoMirror = () => {
    const container = document.getElementById(uniqueIdRef.current);
    if (container) {
      const videoElem = container.querySelector('video');
      if (videoElem) {
        videoElem.style.transform = isMirror ? 'scaleX(-1)' : 'scaleX(1)';
      }
    }
  };

  // カメラ起動用の共通処理
  const startCamera = async (cameraId) => {
    await html5QrCodeRef.current.start(
      cameraId,
      baseConfig,
      qrCodeSuccessCallback,
      qrCodeErrorCallback
    );
    // カメラ起動後に mirror スタイルを更新
    updateVideoMirror();
  };

  useEffect(() => {
    isMountedRef.current = true;

    if (!qrCodeRef.current) {
      console.error('QRコードリーダーのDOM要素が見つかりません。');
      return;
    }

    html5QrCodeRef.current = new Html5Qrcode(uniqueIdRef.current);

    Html5Qrcode.getCameras()
      .then((devices) => {
        if (devices && devices.length) {
          setCameras(devices);
          // 初期は取得したカメラの先頭を利用（インデックス 0 を内カメラと仮定）
          const cameraId = devices[0].id;
          setCurrentCameraIndex(0);
          setIsMirror(true);
          return startCamera(cameraId);
        } else {
          throw new Error('カメラが見つかりませんでした。');
        }
      })
      .then(() => {
        console.log('QRスキャンを開始しました。');
        isScanningRef.current = true;
        setIsScanning(true);
      })
      .catch((err) => {
        console.error(`QRスキャンの開始に失敗: ${err}`);
        if (isMountedRef.current) {
          onScanError(err);
        }
      });

    // クリーンアップ
    return () => {
      isMountedRef.current = false;
      if (html5QrCodeRef.current && isScanningRef.current) {
        html5QrCodeRef.current
          .stop()
          .then(() => {
            html5QrCodeRef.current.clear();
            console.log('QRスキャンを停止しました。');
            isScanningRef.current = false;
            setIsScanning(false);
          })
          .catch((err) => {
            console.error(`QRスキャンの停止に失敗: ${err}`);
          });
      }
    };
  }, [onScanSuccess, onScanError]);

  // isMirror の変更時に video 要素のスタイルを更新（カメラ切り替え時にも対応）
  useEffect(() => {
    const timer = setTimeout(() => {
      updateVideoMirror();
    }, 300);
    return () => clearTimeout(timer);
  }, [isMirror, currentCameraIndex]);

  // カメラ切り替え：一般的なデバイスはカメラが2つと仮定
  const toggleCamera = async () => {
    if (!html5QrCodeRef.current || cameras.length < 2) return;
    try {
      await html5QrCodeRef.current.stop();
      await html5QrCodeRef.current.clear();

      const nextIndex = (currentCameraIndex + 1) % cameras.length;
      setCurrentCameraIndex(nextIndex);

      // インデックス 0 は内カメラ（mirror:true）、1 は外カメラ（mirror:false）とする
      const newMirror = nextIndex === 0 ? true : false;
      setIsMirror(newMirror);

      const nextCamera = cameras[nextIndex];
      const nextCameraId = nextCamera.id;

      await startCamera(nextCameraId);
    } catch (err) {
      console.error('カメラ切り替えに失敗しました:', err);
    }
  };

  // mirror 切り替え用ボタン（カメラ再起動は不要、transform を更新するだけ）
  const toggleMirror = () => {
    setIsMirror(!isMirror);
  };

  return (
    <>
      <div
        id={uniqueIdRef.current}
        ref={qrCodeRef}
        className="qr-reader-container"
        style={{ width: '100%', marginBottom: '20px' }}
      ></div>
      <div style={{ display: 'flex', gap: '10px' }}>
        <button className="p-2 bg-white" onClick={toggleCamera}>
          <FaSync /> カメラ切り替え
        </button>
        <button className="p-2 bg-white" onClick={toggleMirror}>
          左右反転
        </button>
      </div>
    </>
  );
};

QRReader.propTypes = {
  onScanSuccess: PropTypes.func.isRequired,
  onScanError: PropTypes.func,
};

QRReader.defaultProps = {
  onScanError: () => {},
};

export default QRReader;