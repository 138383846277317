import { useState, useEffect } from 'react';
import { useGetNotificationsQuery, useMarkAllAsReadMutation, useMarkNotificationAsReadMutation } from '../slices/notificationsApiSlice';
import { Container, Button, ListGroup } from 'react-bootstrap';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Meta from '../components/Meta';
import Paginate from '../components/Paginate';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { formatDate } from '../utils/dateUtils';
import { toast } from 'react-toastify';

const NotificationScreen = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [readStatus, setReadStatus] = useState(searchParams.get('readStatus') || 'unread');
    const pageParam = Number(searchParams.get('page') || 1);

    const { data, isLoading, error } = useGetNotificationsQuery({
        readStatus,
        page: pageParam,
        limit: 20,
    });
    
    const [markAllAsRead] = useMarkAllAsReadMutation();
    const [markNotificationAsRead] = useMarkNotificationAsReadMutation();

    useEffect(() => {
        // URL クエリを合わせておく
        setSearchParams({ readStatus, page: pageParam });
    }, [readStatus, pageParam, setSearchParams]);

    const handleTabClick = (status) => {
        setReadStatus(status);
        setSearchParams({ readStatus: status, page: 1 }); // タブを切り替えたら 1ページ目に戻す
    };

    const handleMarkAllAsRead = async () => {
        if (!window.confirm('すべての通知を既読にしますか？')) return;
        try {
            await markAllAsRead().unwrap();
        } catch (err) {
            toast.error('通知の既読化に失敗しました');
            console.error(err);
        }
    };

    const handleNotificationClick = async (notification) => {
        if (!notification.read) {
            try {
                await markNotificationAsRead(notification.id).unwrap();
            } catch (err) {
                toast.error('通知の既読化に失敗しました');
                console.error(err);
            }
        }
        if (['order', 'rental', 'cancel'].includes(notification.type)) {
            navigate('/')
        }
    };

    if (isLoading) return <Loader />;
    if (error) return <Message variant="danger">{error?.data?.message || error.message}</Message>;

    const { notifications, pagination } = data || {};
    const { totalPages = 1, currentPage = 1 } = pagination || {};

    return (
        <>
            <Meta
                title='通知 | ふるぷら'
                description='通知の一覧ページです。'
                robots='noindex, nofollow'
            />

            <Container>
                <h1>通知</h1>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <div>
                        <Button
                            variant={readStatus === 'unread' ? 'primary' : 'outline-primary'}
                            onClick={() => handleTabClick('unread')}
                            className="me-2"
                        >
                            未読
                        </Button>
                        <Button
                            variant={readStatus === 'read' ? 'primary' : 'outline-primary'}
                            onClick={() => handleTabClick('read')}
                        >
                            既読
                        </Button>
                    </div>
                    <Button variant="outline-secondary" onClick={handleMarkAllAsRead}>
                        全部既読にする
                    </Button>
                </div>

                <ListGroup variant="flush">
                    {notifications && notifications.length > 0 ? (
                        notifications.map((notification) => (
                            <ListGroup.Item
                                key={notification.id}
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleNotificationClick(notification)}
                            >
                                {!notification.read && <span className="me-2 text-danger">●</span>}
                                <span>{`${formatDate(notification.createdAt)}：${notification.message}`}</span>
                            </ListGroup.Item>
                        ))
                    ) : (
                        <Message>通知がありません</Message>
                    )}
                </ListGroup>

                {totalPages > 1 && (
                    <Paginate currentPage={currentPage} totalPages={totalPages} />
                )}
            </Container>
        </>
    );
};

export default NotificationScreen;