import { useState } from 'react';
import { Container } from 'react-bootstrap';
import { useShopOrders } from '../../hooks/useShopOrders';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import OrderDetailsModal from '../../components/OrderDetailsModal';
import ShopOrderListView from '../../view/ShopOrderListView';

const ShopOrderListScreen = () => {
  // カスタムフックでデータとロジックを取得
  const {
    isLoading,
    error,
    refetch,
    groupedUndelivered,
    rentedItems,
    delivered,
    canceled,
  } = useShopOrders();

  // モーダル管理用 state
  const [showModal, setShowModal] = useState(false);
  const [activeOrder, setActiveOrder] = useState(null);
  const [activeProducts, setActiveProducts] = useState([]);
  const [isRental, setIsRental] = useState(false);
  const [deliveredStatus, setDeliveredStatus] = useState(false);

  // タブ管理
  const [activeTab, setActiveTab] = useState('delivered');

  /**
   * モーダルを開く関数
   * @param {string | number} orderId - オーダーID
   * @param {Array} products - 選択された商品の配列
   * @param {boolean} isRentalItem - レンタルかどうか
   * @param {boolean} isDelivered - 配送済みかどうか
   */
  const handleShowModal = (orderId, products, isRentalItem, isDelivered) => {
    setActiveOrder(orderId);
    setActiveProducts(products);
    setIsRental(isRentalItem);
    setDeliveredStatus(isDelivered);
    setShowModal(true);
  };

  /**
   * モーダルを閉じる関数
   */
  const handleCloseModal = () => {
    setShowModal(false);
    setActiveOrder(null);
    setActiveProducts([]);
    setIsRental(false);
    setDeliveredStatus(false);
  };

  return (
    <Container>
      <h1 className='mt-4'>注文管理ページ</h1>

      {/** 詳細モーダル */}
      <OrderDetailsModal
        key={activeOrder}
        show={showModal}
        onHide={handleCloseModal}
        orderId={activeOrder}
        items={activeProducts}
        isRental={isRental}
        delivered={deliveredStatus}
        refetch={refetch}
      />

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error?.data?.message || error.error}</Message>
      ) : (
        <ShopOrderListView
          /** Presenter に必要な情報だけを渡す */
          groupedUndelivered={groupedUndelivered}
          rentedItems={rentedItems}
          delivered={delivered}
          canceled={canceled}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          handleShowModal={handleShowModal}
        />
      )}
    </Container>
  );
};

export default ShopOrderListScreen;