import { Card, Button, Table } from 'react-bootstrap';
import Message from '../components/Message';
import Tabs from '../components/Tabs';
import GalleryDisplay from '../components/GalleryDisplay';

const ShopOrderListView = ({
  // Container から受け取る props
  groupedUndelivered,
  rentedItems,
  delivered,
  canceled,
  activeTab,
  setActiveTab,
  handleShowModal,
}) => {
    const tabs = [
        { label: '配送済み', value: 'delivered', ariaSelected: activeTab === 'delivered' },
        { label: 'キャンセル', value: 'canceled', ariaSelected: activeTab === 'canceled' },
    ];

    return (
        <>
        {/* --- 未配送 --- */}
        {Object.keys(groupedUndelivered).length > 0 && (
            <>
            <h3 className='alert alert-danger mt-4'>未配送の商品</h3>
            <div className='d-flex flex-wrap gap-3 justify-content-center justify-content-md-start'>
                {Object.entries(groupedUndelivered).map(([orderId, products]) => (
                <Card key={orderId} className="mb-3">
                    <Card.Header
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleShowModal(orderId, products, false, false)}
                    >
                        オーダー {orderId}
                    </Card.Header>
                    <Card.Body className='order-card'>
                        <GalleryDisplay type='shop' products={products} />
                    </Card.Body>
                    <Card.Footer>
                        <Button
                            variant='primary'
                            onClick={(e) => {
                                e.stopPropagation();
                                handleShowModal(orderId, products, false, false);
                            }}
                        >
                            配送先を表示
                        </Button>
                    </Card.Footer>
                </Card>
                ))}
            </div>
            </>
        )}

        {/* --- レンタル中 --- */}
        {rentedItems.length > 0 && (
            <>
            <h3 className='alert alert-warning mt-4'>レンタル中の商品</h3>
            <div className='d-flex flex-wrap gap-3 justify-content-center justify-content-md-start'>
                {rentedItems.map((product) => (
                <Card key={product.id} className="mb-3">
                    <Card.Header
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleShowModal(product.orderId, [product], true, true)}
                    >
                        商品名: {product.name}
                    </Card.Header>
                    <Card.Body className='order-card'>
                        <GalleryDisplay type='shop' products={[product]} />
                    </Card.Body>
                    <Card.Footer>
                        <Button
                            variant='primary'
                            onClick={() => handleShowModal(product.orderId, [product], true, true)}
                        >
                            レンタル情報
                        </Button>
                    </Card.Footer>
                </Card>
                ))}
            </div>
            </>
        )}

        {/* --- 配送済み/キャンセル --- */}
        <h3 className='alert alert-success mt-4'>配送済み/キャンセル</h3>
        <Tabs tabs={tabs} onClick={setActiveTab} />

        {activeTab === 'delivered' && (
            <>
            {Object.keys(delivered).length > 0 ? (
                <Table striped bordered hover responsive className='table-sm'>
                    <thead>
                        <tr>
                        <th>オーダーID</th>
                        <th>商品</th>
                        <th>詳細</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(delivered).map(([orderId, items]) => (
                        <tr key={orderId}>
                            <td>{orderId}</td>
                            <td>
                            {items.map(item => (
                                <div key={item.id}>
                                {item.serviceOption === 'purchase' ? '購入' : 'レンタル'}: {item.name}
                                </div>
                            ))}
                            </td>
                            <td>
                            <Button onClick={() => handleShowModal(orderId, items, false, true)}>
                                詳細
                            </Button>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </Table>
            ) : (
                <Message variant='info'>配送済みの商品はありません</Message>
            )}
            </>
        )}

        {activeTab === 'canceled' && (
            <>
            {Object.keys(canceled).length > 0 ? (
                <Table striped bordered hover responsive className='table-sm'>
                    <thead>
                        <tr>
                        <th>オーダーID</th>
                        <th>商品</th>
                        <th>詳細</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(canceled).map(([orderId, items]) => (
                        <tr key={orderId}>
                            <td>{orderId}</td>
                            <td>
                            {items.map((item) => (
                                <div key={item.id}>
                                {item.serviceOption === 'purchase' ? '購入' : 'レンタル'}: {item.name}
                                </div>
                            ))}
                            </td>
                            <td>
                            <Button onClick={() => handleShowModal(orderId, items, false, false)}>
                                詳細
                            </Button>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </Table>
            ) : (
                <Message variant='info'>キャンセルされた商品はありません</Message>
            )}
            </>
        )}
        </>
    );
};

export default ShopOrderListView;